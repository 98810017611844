import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "figgy",
    meta: {
      title: "价格监测",
    },
    component: () => import("@/views/fliggy/index.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});
export default router;
