import {
  ConfigProvider,
  Tabbar,
  TabbarItem,
  Image,
  Icon,
  Button,
  Divider,
  SubmitBar,
  Popup,
  Cell,
  CellGroup,
  Stepper,
  RadioGroup,
  Radio,
  Form,
  Field,
  CountDown,
  Checkbox,
  Row,
  Col,
  NavBar,
  Overlay,
  ShareSheet,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Tag,
  Loading,
  Empty,
  Collapse,
  CollapseItem,
  CheckboxGroup,
  Grid,
  GridItem,
  Popover,
  Switch
} from "vant";

export default function withInstallVant(app) {
  app
    .use(ConfigProvider)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Image)
    .use(Icon)
    .use(Button)
    .use(Cell)
    .use(CellGroup)
    .use(Divider)
    .use(SubmitBar)
    .use(Popup)
    .use(Stepper)
    .use(RadioGroup)
    .use(Radio)
    .use(Form)
    .use(Overlay)
    .use(Field)
    .use(CountDown)
    .use(Tab)
    .use(Tabs)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Row)
    .use(Col)
    .use(NavBar)
    .use(Swipe)
    .use(SwipeItem)
    .use(ShareSheet)
    .use(Tag)
    .use(Empty)
    .use(Loading)
    .use(Loading)
    .use(Collapse)
    .use(CollapseItem)
    .use(Grid)
    .use(GridItem)
    .use(Popover)
    .use(Switch);
}
