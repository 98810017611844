import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";

import "@/assets/base.scss";
import "windi.css";
import withInstallVant from "@/plugins/vant";

function bootstrap() {
  const app = createApp(App);
  app.use(router).mount("#app");

  withInstallVant(app);

  // Vue.config.ignoredElements = false
  console.log("************", app.config);
  app.config.isCustomElement = (tag) => {
    return tag.startsWith("wx-");
  };
}

bootstrap();
